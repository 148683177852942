import RequestClient from './RequestClient';
import config from '../constants/envs';

const ENDPOINT_GET_RECORD = '/shared/record';

class RecordApiInternalShare extends RequestClient {
  OK_STATUS: string;

  constructor() {
    super(config, 'recordApiInternalApiShare');
    this.OK_STATUS = 'OK';
  }

  async getRecord(code: string | undefined, moduleId: Array<string> | undefined) {
    const data = moduleId && moduleId.length > 0 ? { moduleId: moduleId.join(',') } : {};
    const record = await this.get(`${ENDPOINT_GET_RECORD}/${code}`, data);
    return record;
  }
}

export default new RecordApiInternalShare();
