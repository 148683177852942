import React from 'react';
import {
  Col, Layout, Menu, MenuProps, Row,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import './Header.scss';

import UserMenuComponent from '../../reusables/UserMenu';
import logo from '../../../images/logo_autofactpro.svg';

interface AppHeaderInterface {
  hasSharedAccess?: boolean
}

function AppHeader(props: AppHeaderInterface) {
  const navigate = useNavigate();

  const { hasSharedAccess } = props;

  const menu : any = {
    home: {
      key: 'home',
      action: (): void => {
        navigate('/');
      },
    },
    userMenu: {
      key: 'userMenu',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      action: (): void => {

      },
    },
  };

  const { Header } = Layout;
  const items: MenuProps['items'] = [
    {
      label: false,
      key: menu.userMenu.key,
      icon: <UserMenuComponent />,
    },
    {
      label: 'Inicio',
      key: menu.home.key,
      icon: <HomeOutlined />,
    },
  ];

  const onClick: MenuProps['onClick'] = (e): void => {
    const { key } = e;
    return menu[key].action();
  };

  return (
    <Header
      className="header"
    >
      <Row align="middle">
        <Col span={12} lg={5}>
          {!hasSharedAccess ? (
            <Link to="/">
              <img
                className="logo"
                src={logo}
                alt="App logo"
              />
            </Link>
          )
            : (
              <img
                className="logo"
                src={logo}
                alt="App logo"
              />
            )}

        </Col>
        <Col span={12} lg={19}>
          {!hasSharedAccess && <Menu className="horizontal-menu" onClick={onClick} mode="horizontal" items={items} />}
        </Col>
      </Row>
    </Header>
  );
}

export default AppHeader;
