import { setInformationGroups } from './InformationGroupsSlice';
import {
  IStoreModuleResults, IStoreSections, IStoresInformationGroups, IStoresInformationGroupsModules,
} from '../interfaces';
import { storage } from '../store';

const updateUpgradeableGroups = (dataUpdatable: string[]) => {
  let updatable: {[key: string]: boolean} = {};
  dataUpdatable.forEach((group: string) => {
    updatable = {
      ...updatable,
      [group]: false,
    };
  });
  return updatable;
};

const getInformationGroups = (moduleResults?: IStoreModuleResults[]) => {
  const moduleData = moduleResults?.filter((value: IStoreModuleResults) => !value.module.id.includes('appraisal' || 'abstract'));
  const upgradeableModules: IStoresInformationGroups = {};
  if (moduleData) {
    moduleData.forEach((modules: IStoreModuleResults) => {
      const moduleIdOfUpgradeableModules: string = modules.module.id;
      upgradeableModules[moduleIdOfUpgradeableModules] = {};

      modules.section?.forEach((section: IStoreSections) => {
        if (section.updatable && section.updatable.length > 0) {
          section.data.forEach(({ inputId, inputType, inputValue }) => {
            let informationGroupsItems: IStoresInformationGroupsModules = {};

            if (upgradeableModules[moduleIdOfUpgradeableModules][inputId]
              && upgradeableModules[moduleIdOfUpgradeableModules][inputId].updatable
              && upgradeableModules[moduleIdOfUpgradeableModules][inputId].type === inputType
              && upgradeableModules[moduleIdOfUpgradeableModules][inputId].value === inputValue
            ) {
              upgradeableModules[moduleIdOfUpgradeableModules][inputId].updatable = {
                ...upgradeableModules[moduleIdOfUpgradeableModules][inputId].updatable,
                ...updateUpgradeableGroups(section.updatable),
              };
            } else {
              informationGroupsItems = {
                [inputId]: {
                  type: inputType,
                  value: inputValue,
                  updatable: { ...updateUpgradeableGroups(section.updatable) },
                },
              };
              upgradeableModules[moduleIdOfUpgradeableModules] = { ...upgradeableModules[moduleIdOfUpgradeableModules], ...informationGroupsItems };
            }
          });
        }
      });
    });
    storage.dispatch(setInformationGroups(upgradeableModules));
  }
};

export default getInformationGroups;
