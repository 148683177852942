/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'UserSlice',
  initialState: {},
  reducers: {
    getUser: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  getUser,
} = userSlice.actions;

export default userSlice;
