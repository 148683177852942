/* eslint-disable no-throw-literal */
import cookies from '../helpers/Cookies';

import { InfoRequest } from './interfaces/InfoRequestInterfaces';

const API_KEY_HEADER = 'x-api-key';

const DEFAULT_REQUEST_OPTIONS = {
  url: null,
  apiKey: null,
  headers: {
    Authorization: `Bearer ${cookies().external_auth}`,
    'content-type': 'application/json',
  },
};

const TEXT_ERROR_PAGE_NOT_FOUND = 'PAGE NOT FOUND';
const TEXT_ERROR_SERVER_ERROR = 'SERVER ERROR';
const TEXT_ERROR_HTTP_ERROR_STATUS = 'HTTP ERROR STATUS';

function clientOptions(config: any, endpointName: string) {
  const configNodes = [];

  if (!config || !config.endpoints) {
    return DEFAULT_REQUEST_OPTIONS;
  }

  let nodeConfig = config.endpoints[endpointName];

  while (nodeConfig) {
    configNodes.push(nodeConfig);
    const parentName = nodeConfig.parent;
    if (parentName) {
      nodeConfig = config.endpoints[parentName];
    } else {
      nodeConfig = null;
    }
  }

  configNodes.push(DEFAULT_REQUEST_OPTIONS);

  let result: any = {};
  let headers = null;

  while (configNodes.length > 0) {
    const configNode = configNodes.pop();
    if (configNode.headers) {
      if (headers) {
        headers = Object.assign(headers, configNode.headers);
      } else {
        headers = { ...configNode.headers };
      }
    }
    result = Object.assign(result, configNode);
  }

  while (configNodes.length > 0) {
    const configNode = configNodes.pop();
    if (configNode.headers) {
      if (headers) {
        headers = Object.assign(headers, configNode.headers);
      } else {
        headers = { ...configNode.headers };
      }
    }
    result = Object.assign(result, configNode);
  }

  result.headers = headers;

  if (result.apiKey) {
    if (!result.headers) {
      result.headers = {};
    }
    result.headers[API_KEY_HEADER] = result.apiKey.trim();
  }

  if (!result.url) {
    throw `Missing base url for endpoint ${endpointName}`;
  }

  return result;
}

const fetch = window.fetch.bind(window);
const defaultDependencies: any = {
  fetch,
};

class RequestClient {
  private clientOptions;

  private dependencies;

  constructor(config: any, endpointName: string, dependencies: any = defaultDependencies) {
    this.dependencies = dependencies;
    this.clientOptions = clientOptions(config, endpointName);
  }

  async get(endpoint: string, data: any) {
    return this.send('GET', endpoint, data);
  }

  async post(endpoint: string, data: any) {
    return this.send('POST', endpoint, data);
  }

  async put(endpoint: string, data: any) {
    return this.send('PUT', endpoint, data);
  }

  async delete(endpoint: string, data: any) {
    return this.send('DELETE', endpoint, data);
  }

  async send(method: string, endpoint: string, data: any) {
    const options = this.clientOptions;
    const { url, headers } = options;

    const infoRequest: InfoRequest = {
      url: url + endpoint,
      headers,
      method,
    };

    if (data) {
      if (method === 'GET') {
        infoRequest.params = data;
      } else {
        infoRequest.body = JSON.stringify(data);
      }
    }

    let body = {};
    let params = '';
    if (infoRequest.params) {
      params = new URLSearchParams(infoRequest.params).toString();
      infoRequest.url = `${infoRequest.url}?${params}`;
    }
    try {
      const response = await this.dependencies.fetch(infoRequest.url, infoRequest);
      if (response.status === 400) {
        throw TEXT_ERROR_PAGE_NOT_FOUND;
      } else if (response.status === 500) {
        throw TEXT_ERROR_SERVER_ERROR;
      } else if (!response.ok) {
        throw `${TEXT_ERROR_HTTP_ERROR_STATUS}_${response.status}`;
      }
      body = await response.json();
    } catch (error) {
      return Promise.reject(error);
    }

    return body;
  }
}

export default RequestClient;
