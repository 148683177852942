import { useEffect, useState } from 'react';

const useModal = (
  handleOkModal?: () => void,
  isLoading?: boolean,
  handleCancelModal?: () => void,
  initialState = false,
) => {
  const [toggleModal, setToggleModal] = useState(initialState);

  useEffect(() => {
    if (isLoading && !isLoading)closeModal();
  }, [isLoading]);

  const showModal = () => {
    setToggleModal(true);
  };

  const closeModal = () => {
    setToggleModal(false);
  };

  const handleOk = () => {
    if (handleOkModal) handleOkModal();
  };

  const handleCancel = () => {
    if (handleCancelModal) handleCancelModal();
    closeModal();
  };

  return {
    toggleModal,
    showModal,
    closeModal,
    handleOk,
    handleCancel,
  };
};

export default useModal;
