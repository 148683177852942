/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

import './App.scss';

import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Header from './components/containers/Header';
import Footer from './components/containers/Footer';
import Home from './components/routes/Home';
import Create from './components/routes/Create';
import View from './components/routes/View';
import PageNotFound from './components/routes/PageNotFound';
import CloseActivity from './components/routes/CloseActivity';
import regex from './constants/regex';
import CPanel from './components/containers/CPanel';
import CreatePDF from './components/routes/DownloadPDF';
import FalseSale from './tests/retail/FalseSale/FalseSale';
import ConfirmModal from './components/reusables/ConfirmGeneralModal/ConfirmGeneralModal';
import ModalContext from './components/context/ModalContext/ModalContext';

function App() {
  const [hasSharedAccess, setHasSharedAccess] = useState(true);
  const { pathname } = useLocation();

  const {
    toggleModal, title, contentModal, closeModal, okText,
  } = useContext(ModalContext);

  useEffect(() => {
    const { sharedAccessRoute } = regex;
    setHasSharedAccess(sharedAccessRoute.test(pathname));
  }, []);

  return (
    <div className="app">
      <Layout className="af-layout">
        <Header hasSharedAccess />
        <Content className="af-content">
          <Routes>
            <Route path="*" element={<CloseActivity />} />
          </Routes>
        </Content>
        <Footer />
      </Layout>
      {/* {
        pathname.includes('descargar-pdf') || pathname.includes('publico/retail') ? (
          <Routes>
            <Route path="/ver/:code/descargar-pdf" element={<CreatePDF />} />
            <Route path="/publico/retail/ejemploA" element={<FalseSale typeTest="A" />} />
            <Route path="/publico/retail/ejemploB" element={<FalseSale typeTest="B" />} />
          </Routes>
        ) : (
          <>
            <CPanel />
            <Layout className="af-layout">
              <Header hasSharedAccess={hasSharedAccess} />
              <Content className="af-content">
                <Routes>
                  {!hasSharedAccess
                    ? (
                      <>
                        <Route path="/" element={<Home />} />
                        <Route path="/crear" element={<Create />} />
                        <Route path="/ver/:code" element={<View />} />
                      </>
                    )
                    : (
                      <Route path="publico/ver/:code" element={<View hasSharedAccess={hasSharedAccess} />} />
                    )}
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Content>
              <Footer />
            </Layout>
          </>
        )
      }
      <ConfirmModal
        handleOk={closeModal}
        title={title}
        toggleModal={toggleModal}
        okText={okText}
        content={contentModal}
      /> */}
    </div>
  );
}

export default App;
