/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const modulesLoadingSlice = createSlice({
  name: 'ModulesLoadingSlice',
  initialState: null,
  reducers: {
    setModulesLoading: (state, action) => action.payload,
  },
});

export const { setModulesLoading } = modulesLoadingSlice.actions;

export default modulesLoadingSlice;
