import modules from '../enums/Modules';

/* eslint-disable @typescript-eslint/no-var-requires */
const ENV = require('../config.json');

const endpoints = {
  recordApiInternalApi: {
    url: ENV.URL_INTERNAL_RECORD || 'https://api.expediente.pro.autofact.qa/expediente-api-internal/v1',
    apiKey: ENV.API_KEY_INTERNAL_RECORD || '0xAn8BSJpc9zDtXQA4be8jqq9adS6NM66CSobTb0',
  },
  recordApiInternalApiShare: {
    url: ENV.URL_INTERNAL_RECORD_SHARE || 'https://api.expediente.pro.autofact.qa/expediente-api-internal/v1',
    apiKey: ENV.API_KEY_INTERNAL_RECORD_SHARE || '4fq0YCaabE60icc3OIcBT6hncAyjezeh7IEQWYPB',
  },
};

const app = {
  url: ENV.URL || 'https://expediente.pro.autofact.qa',
  gtm: ENV.GTM || 'GTM-K4RMSSK',
  tablePageSize: ENV.TABLE_PAGE_SIZE || '7',
  autoRefreshTime: ENV.AUTO_REFRESH_TIME || '30000',
  maxAutoRefreshRetries: ENV.MAX_AUTO_REFRESH_RETRIES || '20',
  dateFormat: ENV.DATE_FORMAT || 'DD/MM/YYYY',
  dateFullFormat: ENV.DATE_FULL_FORMAT || 'DD/MM/YYYY hh:mm:ss A',
  loginRedirect: ENV.LOGIN_REDIRECT || 'https://pro.autofact.qa/mx',
  table: {
    noData: 'Sin resultados',
  },
  module: {
    appraisal: modules.appraisal,
  },
  concesSite: ENV.URL_CONCES_SITE || 'https://pro.autofact.qa',
  avoidLoaderSection: (ENV.AVOID_LOADER_SECTION || '').split(','),
  showConnectionProblemsRepuve: ENV.SHOW_CONNECTION_PROBLEMS_REPUVE === 'true',
  showLimitedSources: ENV.SHOW_LIMITED_SOURCES === 'true',
  showRepuveAlert: ENV.SHOW_REPUVE_ALERT === 'true',
  timeRepuveStatus: ENV.TIME_REPUVE_STATUS || '10',
  millisecondsToReloadPDF: Number(ENV.MILLISECONDS_TO_RELOAD_PDF || '5') * 1000,
  queryRetriesPDF: Number(ENV.QUERY_RETRIES_PDF || '10'),
  secondsSourceConnection: Number(ENV.SECONDS_SOURCE_CONNECTION || '180'),
  secondsShowAlertReleaseRecord: Number(ENV.SECONDS_SHOW_ALERT_RELEASE_RECORD || '60'),
  urlFinalTest: ENV.URL_FINAL_TEST || 'https://mx.autofact.qa/error-inesperado',
};

export default {
  endpoints,
  app,
};
