import { isObject } from 'underscore';
import ManualRevisionErrorType from '@autofact/autofact-lib-ts-names/build/src/manualRevisionErrorType';
import RequestClient from './RequestClient';
import config from '../constants/envs';
import { IAlerts, IDataResponseAlerts, IResponseAlerts } from './interfaces/Alerts';
import { IInformationGroupsToBeUpdated } from '../components/retryByOperationGroup/UpdateOfInformationGroups/UpdateOfInformationGroupsInterfaces';
import ArasResponseInterface from './interfaces/ArasResponseInterface';
import { IStoreKeyValue } from '../store/interfaces';

export interface IAppraisalData {
  year?: IStoreKeyValue,
  brand?: IStoreKeyValue,
  model?: IStoreKeyValue,
  modelId: string,
  recordCode: string | undefined,
  mileage: string,
}

const ENDPOINT_GET_RECORD = '/public/record';
const ENDPOINT_GET_YEARS = '/public/year';
const ENDPOINT_GET_BRANDS = '/public/brand';
const ENDPOINT_GET_MODELS = '/public/model';
const ENPOINT_POST_RECORD = '/public/record';
const ENPOINT_POST_VERSIONS = '/public/version';
const ENDPOINT_POST_APPRAISAL = '/public/appraisal';
const ENDPOINT_USER = '/public/user';
const ENDPOINT_USERS = '/public/users';
const ENDPOINT_GET_ALERTS = '/public/alert';
const ENDPOINT_SHARE = (code: string) => `/public/record/${code}/share`;
const ENDPOINT_NOTIFY_USER = '/public/management/notify-user';
const RECORD_COMPLETNESS = '/public/management/record-completeness';
const ENDPOINT_GET_RECORD_DOCUMENT = '/public/documents';

class RecordApiInternal extends RequestClient {
  OK_STATUS: string;

  constructor() {
    super(config, 'recordApiInternalApi');
    this.OK_STATUS = 'OK';
  }

  async shareRecrod(code: string) {
    const sharedRecord = await this.post(ENDPOINT_SHARE(code), null);
    return sharedRecord;
  }

  async getRecords(searchUrl: any | undefined) {
    const records = await this.get(ENDPOINT_GET_RECORD, searchUrl);
    return records;
  }

  async getRecord(code: string | undefined, moduleId: Array<string> | undefined) {
    const data = moduleId && moduleId.length > 0 ? { moduleId: moduleId.join(',') } : {};
    const record = await this.get(`${ENDPOINT_GET_RECORD}/${code}`, data);
    return record;
  }

  async getYears() {
    const records = await this.get(ENDPOINT_GET_YEARS, {});
    return records;
  }

  async getBrands(year: number | undefined) {
    const data = {
      year,
    };
    const records = await this.get(ENDPOINT_GET_BRANDS, data);
    return records;
  }

  async getModels(brand: number | undefined) {
    const data = {
      brand,
    };
    const records = await this.get(ENDPOINT_GET_MODELS, data);
    return records;
  }

  async createRecord(data: any) {
    const record = await this.post(ENPOINT_POST_RECORD, data);
    return record;
  }

  async getVersions(year: string, brand: string, model: string) {
    const data = {
      year,
      brand,
      model,
    };
    const records = await this.post(ENPOINT_POST_VERSIONS, data);
    return records as ArasResponseInterface;
  }

  async createAppraisal(data: IAppraisalData) {
    const result = await this.post(ENDPOINT_POST_APPRAISAL, data);
    return result as ArasResponseInterface;
  }

  async getUser() {
    return this.get(ENDPOINT_USER, {});
  }

  async getUsers() {
    return this.get(ENDPOINT_USERS, null);
  }

  async getAlerts(service: string[] | undefined = ['repuve']) {
    const data = { service };
    const responseAlerts: IResponseAlerts = await this.get(ENDPOINT_GET_ALERTS, data) as IResponseAlerts;
    const dataAlerts: IDataResponseAlerts[] = isObject(responseAlerts.data) ? [responseAlerts.data] as IDataResponseAlerts[] : responseAlerts.data;
    const alerts: IAlerts = {};
    dataAlerts.forEach(({ service: serv, status }: IDataResponseAlerts) => {
      alerts[serv] = status;
    });

    return alerts;
  }

  async updateFile(isRecordOk: boolean, recordCode: string, selectedManualRevision?: ManualRevisionErrorType) {
    const data = selectedManualRevision ? { isRecordOk, recordCode, selectedManualRevision } : { isRecordOk, recordCode };
    const response = await this.get(ENDPOINT_NOTIFY_USER, data);
    return response;
  }

  async updateInformationGroups(dataToBeUpdated: IInformationGroupsToBeUpdated) {
    const response = await this.post(RECORD_COMPLETNESS, dataToBeUpdated);
    return response;
  }

  async getRecordDocument(code: string) {
    const data = { code };
    const response = await this.get(ENDPOINT_GET_RECORD_DOCUMENT, data);
    return response;
  }
}

export default new RecordApiInternal();
