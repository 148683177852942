import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { recordSlice } from './record/RecordSlice';
import { userSlice } from './user/UserSlice';
import { tabSelectedSlice } from './tabs/TabsSlice';
import { alertsStateSlice } from './alerts/AlertsSlice';
import { informationGroupsSlice } from './informationGroups/InformationGroupsSlice';
import { informationGroupsToBeUpdatedSlice } from './informationGroupsToBeUpdated/InformationGroupsToBeUpdatedSlice';
import { modulesLoadingSlice } from './modulesLoading/ModulesLoading';

const combinedReducers = combineReducers({
  alertsState: alertsStateSlice.reducer,
  informationGroups: informationGroupsSlice.reducer,
  informationGroupsToBeUpdated: informationGroupsToBeUpdatedSlice.reducer,
  modulesLoading: modulesLoadingSlice.reducer,
  recordVehicle: recordSlice.reducer,
  tabSelected: tabSelectedSlice.reducer,
  user: userSlice.reducer,
});

export const storage = configureStore({
  reducer: combinedReducers,
});

export type appDispatch = typeof storage.dispatch;

export default storage;
