import { useMemo, useState } from 'react';
import ModalContext from './ModalContext';
import useModal from '../../../hooks/useModal/useModal';
import { IModalProvider } from './interfaces';

function ModalProvider({ children }: IModalProvider) {
  const [title, setTitle] = useState<string>('');
  const [okText, setOkText] = useState<string>('');
  const [contentModal, setContentModal] = useState<React.JSX.Element | string>('');
  const {
    toggleModal,
    showModal,
    handleCancel,
    closeModal,
  } = useModal();

  const modalContextValue = useMemo(() => ({
    closeModal,
    contentModal,
    handleCancel,
    setContentModal,
    setTitle,
    showModal,
    title,
    toggleModal,
    okText,
    setOkText,
  }), [contentModal, handleCancel, setContentModal, setTitle, showModal, title, toggleModal, closeModal, okText, setOkText]);
  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
}
export default ModalProvider;
