import React from 'react';
import {
  Col, Row,
} from 'antd';
import './Footer.scss';
import { LockOutlined, CopyrightOutlined } from '@ant-design/icons';
import { Footer } from 'antd/lib/layout/layout';
import logoFooter from '../../../images/logo_autofact_footer.svg';

function AppFooter() {
  return (
    <Footer
      className="footer"
    >
      <Row align="middle">
        <Col span={24}>
          <img
            className="logo-footer"
            src={logoFooter}
            alt="App logo footer"
          />
          <p>
            <CopyrightOutlined />
            {' '}
            Todos los derechos reservados
          </p>
          <p>
            <LockOutlined />
            {' '}
            Sitio Seguro
          </p>
        </Col>
      </Row>
    </Footer>
  );
}

export default AppFooter;
