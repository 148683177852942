/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const recordSlice = createSlice({
  name: 'RecordSlice',
  initialState: {
    data: {},
  },
  reducers: {
    getDataRecord: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setModuleRecord: (state: any, action) => {
      if (state.data.moduleResults === undefined) {
        return { ...state, data: { ...action.payload } };
      }
      const newModuleResults = [];
      for (let x = 0, xLength = state.data.moduleResults.length; x < xLength; x += 1) {
        const currentModule = state.data.moduleResults[x];
        const loadingModule = action.payload.moduleResults.find((m: any) => m.module.id === currentModule.module.id);

        if (loadingModule && currentModule && (
          currentModule.updated !== loadingModule.updated
          || JSON.stringify(currentModule) !== JSON.stringify(loadingModule)
        )) {
          newModuleResults.push(loadingModule);
        } else {
          newModuleResults.push(currentModule);
        }
      }
      return { ...state, data: { ...action.payload, moduleResults: [...newModuleResults] } };
    },
  },
});

export const {
  getDataRecord,
  setModuleRecord,
} = recordSlice.actions;

export default recordSlice;
