import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { storage } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import defaultTheme from './defaultTheme';
import envs from './constants/envs';
import ModalProvider from './components/context/ModalContext/ModalProvider';

const { app: { gtm: gtmId } } = envs;
const tagManagerArgs = {
  gtmId,
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Router>
      <ConfigProvider
        theme={defaultTheme}
      >
        <Provider store={storage}>
          <ModalProvider>
            <App />
          </ModalProvider>
        </Provider>
      </ConfigProvider>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
