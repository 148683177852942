const defaultTheme = {
  token: {
    fontFamily: "Source Sans Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    colorSuccess: '#237804',
    colorError: '#DB2929',
    colorWarning: '#FFAC1A',
  },
  components: {
    Select: {
      fontSize: 16,
      controlHeight: 40,
    },
    Input: {
      fontSize: 16,
      controlHeight: 40,
    },
    DatePicker: {
      fontSize: 14,
      controlHeight: 40,
    },
    Form: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
    },
    Steps: {
      colorPrimary: '#FFAC1A',
      colorText: '#15257A',
      colorTextDescription: '#15257A',
      colorFillContent: '#FFAC1A',
      colorTextLabel: '#FFFFFF',
      fontSize: 14,
    },
    Modal: {
      borderRadiusLG: 20,
    },
    Menu: {
      fontSize: 16,
    },
    Card: {
      borderRadiusLG: 16,
    },
  },
};

export default defaultTheme;
