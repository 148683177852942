/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import KeyTabs from '../../enums/KeyTabs';

export const tabSelectedSlice = createSlice({
  name: 'TabSelectedSlice',
  initialState: KeyTabs['precio-de-mercado'],
  reducers: {
    getTabSelected: (state, action) => action.payload,
    setInitialScreen: (state, action) => action.payload,
  },
});

export const {
  getTabSelected,
  setInitialScreen,
} = tabSelectedSlice.actions;

export default tabSelectedSlice;
