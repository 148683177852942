/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const alertsStateSlice = createSlice({
  name: 'AlertsStateSlice',
  initialState: {
    repuve: 1,
    ocra: 1,
    rapi: 1,
  },
  reducers: {
    setAlertStatus: (state, action) => {
      state.repuve = action.payload.repuve;
    },
  },
});

export const { setAlertStatus } = alertsStateSlice.actions;

export default alertsStateSlice;
