import robotClosingOperation from '../../../images/robot_closing_operation.svg';
import './CloseActivity.scss';

function CloseActivity() {
  return (
    <header className="App-header">
      <div className="centered-container">
        <div className="centered-container__content">
          <img
            src={robotClosingOperation}
            alt="robot closing operation"
            className="centered-container__content-image"
          />
          <div className="centered-container__content-text">
            <h3 className="centered-container__content-text-heading">
              Aviso de cierre de operación Autofact México
            </h3>
            <p className="centered-container__content-text-paragraph">
              Lamentamos comunicar a todos nuestros clientes y usuarios que hemos decidido finalizar las operaciones en México.
            </p>
            <span className="centered-container__content-text-span">
              Agradecemos la confianza y el feedback que nos permitió crecer durante este año y medio de funcionamiento.
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default CloseActivity;
