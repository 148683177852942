/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface IInformationGroupsToBeUpdatedState {
  [key: string]: {
    value: string;
    updatable: {
      [key: string]: boolean;
    }
  };
}
const initialState: IInformationGroupsToBeUpdatedState = {};

export const informationGroupsToBeUpdatedSlice = createSlice({
  name: 'informationGroupsToBeUpdated',
  initialState,
  reducers: {
    addInformationGroup: (state, action) => {
      const newState = action.payload;
      return { ...state, ...newState };
    },
    deleteInformationGroup: (state, action) => {
      const keyToDelete: string = action.payload;
      delete state[keyToDelete];
    },
  },
});

export const { addInformationGroup, deleteInformationGroup } = informationGroupsToBeUpdatedSlice.actions;

export default informationGroupsToBeUpdatedSlice;
