/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IStoresInformationGroups } from '../interfaces';

const initialState: IStoresInformationGroups = {};

export const informationGroupsSlice = createSlice({
  name: 'InformationGroupsSlice',
  initialState,
  reducers: {
    setInformationGroups: (state, action) => ({ ...state, ...action.payload }),
    updateInformationGroups: (state, action) => {
      const { payload: { id, moduleName, newUpdatable } } = action;
      state[moduleName][id].updatable = newUpdatable;
    },
    deleteInformationGroups: (state, action) => {
      const { payload: { groupName, inputValue, moduleName } } = action;
      const keysOfTheObjectsByModule: string[] = Object.keys(state[moduleName]);
      keysOfTheObjectsByModule.forEach((keyOfTheObjectsByModule: string) => {
        if (state[moduleName][keyOfTheObjectsByModule].value === inputValue) {
          state[moduleName][keyOfTheObjectsByModule].updatable[groupName] = !state[moduleName][keyOfTheObjectsByModule].updatable[groupName];
        }
      });
    },
  },
});

export const { setInformationGroups, updateInformationGroups, deleteInformationGroups } = informationGroupsSlice.actions;

export default informationGroupsSlice;
