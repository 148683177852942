import { Dropdown, MenuProps } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import envs from '../../../constants/envs';
import recordServiceTemp from '../../../store/record/RecordService';

interface customProps {
  services: {
    recordService: any,
  },
}

const defaultDependencies:customProps = {
  services: {
    recordService: recordServiceTemp,
  },
};

export default function UserMenu({ dependencies = defaultDependencies }:any) {
  const { services: { recordService } } = dependencies;
  const userData = useSelector((state:any) => state.user);

  useEffect(() => {
    (async () => {
      await recordService?.getUserData();
    })();
  }, []);

  const userItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="user-menu-overlay-content">
          <div>Nombre de usuario</div>
          <div>{ userData?.mail ? userData.mail : '' }</div>
          <div>
            <Link to={`${envs.app.concesSite}/auth/logout`}>
              <LogoutOutlined />
              <span>Cerrar sesión</span>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items: userItems }} placement="bottomRight" arrow={{ pointAtCenter: true }} overlayClassName="user-menu-overlay">
      <UserOutlined className="user-menu-trigger" />
    </Dropdown>
  );
}
