import { modules } from '@autofact/autofact-lib-ts-names';
import { setModuleRecord } from './RecordSlice';
import { getUser } from '../user/UserSlice';
import { storage } from '../store';
import ArasResponseInterface from '../../libraries/interfaces/ArasResponseInterface';
import RecordApiInternal from '../../libraries/RecordApiInternal';
import RecordApiInternalShare from '../../libraries/RecordApiInternalShare';
import RecordResponseInterface, { ModuleResults } from '../../interfaces/RecordResponse';
import env from '../../constants/envs';
import { IStoreModuleResults } from '../interfaces';
import getInformationGroups from '../informationGroups/InformationGroups';
import Section from '../../interfaces/Section';
import InputType from '../../enums/InputType';
import { setModulesLoading } from '../modulesLoading/ModulesLoading';

interface DependenciesRecordService {
  clients: {
    recordApiInternal: any;
    recordApiInternalShare: any;
  };
}

const defaultDependencies: DependenciesRecordService = {
  clients: {
    recordApiInternal: RecordApiInternal,
    recordApiInternalShare: RecordApiInternalShare,
  },
};

const orderSubsectionsIdentificationAnalysis = [
  'IdentificationHeader',
  'RepuveIdentification',
  'Theft',
  'HistoricalQuery',
  'NivIdentification',
];

export class RecordService {
  dependencies: DependenciesRecordService | any;

  timer: ReturnType<typeof setInterval> | undefined;

  constructor(
    dependencies: DependenciesRecordService | any = defaultDependencies,
  ) {
    this.dependencies = dependencies;
  }

  serviceGetDataRecord = async (
    code: string | undefined,
    moduleId: Array<string>,
    hasSharedAccess?: boolean,
  ) => {
    const { app: { loginRedirect } } = env;
    const {
      clients: { recordApiInternal, recordApiInternalShare },
    } = this.dependencies;

    let request = recordApiInternal;
    if (hasSharedAccess !== undefined && hasSharedAccess === true) {
      request = recordApiInternalShare;
    }

    let dataRecord: RecordResponseInterface = {} as RecordResponseInterface;
    let modulesLoading: Array<string> = [];
    try {
      const response: ArasResponseInterface = await request.getRecord(
        code,
        moduleId,
      );
      if (!response) {
        throw new Error('ERROR_EMPTY_DATA_RECORD');
      }

      dataRecord = response.data;
      const dataIdentificationAnalysis = dataRecord?.moduleResults.find((module: ModuleResults) => module.module.id === modules.identificationAnalysis);
      const subsectionIdentificationAnalysis = dataIdentificationAnalysis?.section?.find((section: Section) => section.title === InputType.niv);
      const dataNivIdentification = subsectionIdentificationAnalysis?.data.find((value: any) => value.source === 'repuve');
      if (dataNivIdentification) dataNivIdentification.dataType = 'RepuveIdentification';
      subsectionIdentificationAnalysis?.data.sort((a, b) => orderSubsectionsIdentificationAnalysis.indexOf(a.dataType) - orderSubsectionsIdentificationAnalysis.indexOf(b.dataType));

      dataRecord.cPanelMode = response.extra && response.extra.cPanelMode;
      modulesLoading = dataRecord.moduleResults.flatMap((m: any) => (m.module.loader === true ? [m.module.id] : []));
    } catch (error) {
      console.error(error);
      if (hasSharedAccess) {
        window.location.href = loginRedirect;
      }
    }
    storage.dispatch(setModuleRecord(dataRecord));
    storage.dispatch(setModulesLoading(modulesLoading));
    getInformationGroups(dataRecord.moduleResults as IStoreModuleResults[]);
    return modulesLoading;
  };

  updateDataRecord = (dataRecord: any) => {
    storage.dispatch(setModuleRecord(dataRecord));
  };

  getUserData = async () => {
    const {
      clients: { recordApiInternal },
    } = this.dependencies;
    const user: any = {};
    try {
      const response: ArasResponseInterface = await recordApiInternal.getUser();
      if (!response) {
        throw new Error('ERROR_EMPTY_DATA_RECORD');
      }
      const data: any = response?.data ? response?.data : {};
      if (Object.keys(data).length > 1) {
        ({ mail: user.mail, name: user.name } = data);
      }
    } catch (error) {
      console.error(error);
    }
    if (user && user.mail) {
      storage.dispatch(getUser(user));
    }
  };
}

const recordService = new RecordService();
export default recordService;
