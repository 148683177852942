const cookies = function () {
  const pairs = document.cookie.split(';');
  const myCookies :any = {};
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    myCookies[(`${pair[0]}`).trim()] = unescape(pair.slice(1).join('='));
  }
  return myCookies;
};

export default cookies;
